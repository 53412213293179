import { Box } from "@mui/material";
import { useEffect } from "react";

interface DashboardProps {
  title: string;
}

export const Dashboard = ({ title }: DashboardProps)=> {
  useEffect(() => { document.title = title} , []);

  return (
    <Box m="20px">
      Dashboard
    </Box>
  );
};

