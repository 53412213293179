import { Box, useTheme, Button, Typography, Dialog, DialogContent, DialogActions, TextField, CircularProgress, LinearProgress, InputLabel, Select, SelectChangeEvent, MenuItem } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import LambdaGatewayApiService from "../../services/LambdaGatewayApiService";
import Result from "./Result";

interface SendToDeliveryProps {
  title: string;
}

export const SendToDelivery = ({ title }: SendToDeliveryProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => { document.title = title} , []);

  const [open, setOpen] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [isApiKeyProvided, setIsApiKeyProvided] = useState(false);
  const [result, setResult] = useState<{isSuccess: boolean, resultHtml: string}>({ isSuccess: false , resultHtml: "" });
  const [loading, setLoading] = useState(false);

  const [seller, setSeller] = useState(0);

  useEffect(() => { setIsApiKeyProvided(apiKey.trim() !== "") } , [apiKey]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSellerChange = (event: SelectChangeEvent<unknown>) => {
    setSeller(event.target.value as number);
  };

  const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setOpen(false);

    LambdaGatewayApiService.FinalizeSupplyAsync(seller, apiKey)
      .then(result => { 
        setResult({ isSuccess: true, resultHtml: result })
      })
      .catch(e => {
        setResult({ isSuccess: false, resultHtml: e.message })
      })
      .finally(() => { setLoading(false) });
  }

  return (
    <Box m="20px">
      <Header title="Завершить отгрузку" subtitle="Переместить текущую отгрузку WB на доставку" />
      <form id="finalizeSupplyForm" onSubmit={handleSubmit}>

      <Box
        display="grid"
        mb="20px"
      >
        <InputLabel sx={{ m: "0 0 -5px 0", fontSize: "10px" }} variant="filled" id="sellerSelectLabel">Магазин</InputLabel>
        <Select
          fullWidth
          variant="filled"
          labelId="sellerSelectLabel"
          id="sellerSelect"
          value={seller}
          label="Магазин"
          onChange={handleSellerChange}
        >
          <MenuItem value={0}>Топ Мастер</MenuItem>
          <MenuItem value={1}>Копия Топ Мастер</MenuItem>
          <MenuItem value={2}>TEST</MenuItem>
        </Select>
      </Box>
      <Box
        display="grid"
        mb="20px"
      >
        <TextField
          variant="filled"
          type="password"
          autoComplete="new-password"
          label="Ключ"
          value={apiKey}
          onChange={e => setApiKey(e.target.value)}
          name="apikey"
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      <Box
        display="grid"
        mb="20px"
      >
        <Button
          onClick = {handleClickOpen}
          disabled = {!isApiKeyProvided || loading}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            gridColumn: "span 4",
          }}
        >
          Переместить
        </Button>
      </Box>

      <Box
        display="grid"
        mb="20px"
      >
        {loading && (
            <LinearProgress  sx={{
              backgroundColor: colors.greenAccent[800],
            }}/>
        )}
      </Box>

      <Box display="grid">
          <Result isSuccess={result.isSuccess} resultHtml={result.resultHtml}></Result>
      </Box>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            background: colors.primary[400]
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h3" fontWeight="bold" sx={{ color: colors.redAccent[500] }}>
            Внимание
          </Typography>
          <Typography>
            WB отгрузку будет невозможно изменить/отменить.
          </Typography>
          <Typography>
            Вы уверены?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick = {handleClose}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Отмена
          </Button>
          <Button form='finalizeSupplyForm' type="submit" color="primary"
            sx={{
              backgroundColor: colors.redAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Продолжить
          </Button>
        </DialogActions>
      </Dialog>
      </form>
    </Box>
  );
};

