import axios, { AxiosInstance } from "axios";

export default class LambdaGatewayApiService {
  private static httpClient: AxiosInstance = axios.create({
    baseURL: "https://a18praysb5.execute-api.eu-north-1.amazonaws.com",
  });

  public static async FinalizeSupplyAsync(seller: number, apiKey: string): Promise<string> {
    let url = "";
    switch(seller) { 
      case 0: { 
        url = "/default/MSSupplyFinalizer";
        break; 
      } 
      case 1: { 
        url = "/default/Aleshkov_MSSupplyFinalizer";
        break; 
      }
      case 2: { 
        url = "/default/TESTAPIGATEWAY";
        break; 
      } 
    } 

    const response = await this.httpClient.post(url, null, { headers: { "x-api-key": apiKey }});
    if (response) {
      if (response.status !== 200) 
          throw Error(response.data);

      return response.data;
    }

    throw Error("");
  }
}