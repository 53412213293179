import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  SubMenu,
  MenuItem,
  menuClasses
} from "react-pro-sidebar";
import { Link, useLocation  } from "react-router-dom";
import { tokens } from "../../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Logo from "./Logo";
import MoySkladIcon from "./MoySkladIcon";
import WildberriesIcon from "./WildberriesIcon";
import { SidebarContext } from "./sidebarContext"

interface ItemProps {
  title: string;
  to: string;
  icon?: JSX.Element;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === to}
      style={{
        color: colors.grey[100],
      }}
      icon={icon}
      onClick={() => setSelected(to)}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {pathname} = useLocation();
  const [selected, setSelected] = useState("/");

  const [collapsed, setCollapsed] = useState(false);

  const { setBroken, toggled, setToggled } = useContext(SidebarContext)

  useEffect(() => {
    setSelected(pathname);
  }, [pathname]);

  return (
    
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <ProSidebar
          backgroundColor={`${colors.primary[400]}`}
          breakPoint="md"
          onBreakPoint={setBroken}
          toggled={toggled}
          onBackdropClick={() => setToggled(false)}
          collapsed={collapsed}
          rootStyles={{
            border: "none",
            [`.${menuClasses.active}`]: {
              color: "#6870fa",
            },
            [`.${menuClasses.button}`]: {
              backgroundColor: colors.primary[400],
            },
            [`.${menuClasses.button}:hover`]: {
              color: "#868dfb !important",
              backgroundColor: `${colors.primary[800]} !important`,
            },
            [`.${menuClasses.subMenuContent}:hover`]: {
              backgroundColor: `${colors.primary[800]} !important`,
            },
            [`.${menuClasses.subMenuContent} a`]: {
              paddingLeft: collapsed ? "20px" : "40px",
              paddingRight: "20px"
            },
          }}
        >
          <Menu menuItemStyles={{}}>
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setCollapsed(!collapsed)}
              icon={collapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!collapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography variant="h3" color={colors.grey[100]}>
                  </Typography>
                  <IconButton onClick={() => setCollapsed(!collapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!collapsed && (
              <Box mb="25px" mr="10px" ml="10px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Logo  width="100px" height="100px"/>
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                  </Typography>
                </Box>
              </Box>
            )}

            <Box >
              <Item
                  title="Dashboard"
                  to="/"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <SubMenu 
                  label="Wildberries" 
                  defaultOpen={true}
                  active={selected === "/sendToDelivery"} 
                  icon={<WildberriesIcon />}  
                  rootStyles={{
                    color: colors.grey[100],
                  }}>
                  <Item
                    title="Перевод в доставку"
                    to="/sendToDelivery"
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>

                <SubMenu 
                  label="Мой склад" 
                  defaultOpen={true}
                  active={selected === "/convertExcelToMoySklad"} 
                  icon={<MoySkladIcon />}  
                  rootStyles={{
                    color: colors.grey[100],
                  }}
                >
                  <Item
                    title="WB заказы Excel конвертер"
                    to="/convertExcelToMoySklad"
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
            </Box>
          </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
