import { Box } from "@mui/material";
import { useEffect } from "react";

interface ConvertExcelToMoySkladProps {
  title: string;
}

export const ConvertExcelToMoySklad = ({ title }: ConvertExcelToMoySkladProps) => {
  useEffect(() => { document.title = title} , []);

  
  return (<Box m="20px">
    ConvertExcelToMoySklad
  </Box>);
};
  