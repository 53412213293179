import { DisableRuleCommand, EnableRuleCommand, EventBridgeClient, ListRulesCommand, RuleState } from "@aws-sdk/client-eventbridge";

export default class EventBridgeRulesService 
{
  public static async GetListRulesAsync(apiKey: string): Promise<{name: string, enabled: boolean}[]> {
    const client = new EventBridgeClient(
    {
        region: 'eu-north-1',
        credentials: EventBridgeRulesService.GetCredentials(apiKey)
    });

    const input = {
      NamePrefix: "RunEvery",
      EventBusName: "default",
      Limit: 5,
    };
    const command = new ListRulesCommand(input);
    const response = await client.send(command);

    return response.Rules === undefined ? [] : response.Rules.map(r => ({ 
        name: r.Name!,
        enabled: r.State === RuleState.ENABLED
    }));
  }

  public static async EnableRuleAsync(apiKey: string, name: string): Promise<void> {
    const client = new EventBridgeClient(
    {
        region: 'eu-north-1',
        credentials: EventBridgeRulesService.GetCredentials(apiKey)
    });

    const input = {
      Name: name,
      EventBusName: "default",
    };
    const command = new EnableRuleCommand(input);
    await client.send(command);
  }

  public static async DisableRuleAsync(apiKey: string, name: string): Promise<void> {
    const client = new EventBridgeClient(
    {
        region: 'eu-north-1',
        credentials: EventBridgeRulesService.GetCredentials(apiKey)
    });

    const input = {
      Name: name,
      EventBusName: "default",
    };
    const command = new DisableRuleCommand(input);
    await client.send(command);
  }

  private static GetCredentials(apiKey: string): {accessKeyId: string, secretAccessKey: string}
  {
        var parts = apiKey.split(":");
        return {
            accessKeyId: parts[0],
            secretAccessKey: parts[1]
        }
  }
}
