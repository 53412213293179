import { useTheme } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";

interface LogoProps {
    width: string;
    height: string;
}

const Logo = ({ width, height }: LogoProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} version="1.1" style={{fillRule: 'evenodd', clipRule: "revert-layer"}} viewBox="0 0 7302 3839">
        <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer"/>
            <path className="fil0" 
                d="M3941 995c-205,683 -459,1435 -398,2155 11,138 49,260 92,391 18,24 30,109 182,298 23,-49 35,-148 55,-216 344,-1110 471,-1435 1286,-2289 130,-136 233,-222 395,-318 -36,310 -216,687 -276,1037 -90,540 -103,1281 456,1585 426,232 825,-85 1070,-420 191,-261 379,-776 473,-1085 11,-35 40,-168 18,-199 -39,-55 -82,-9 -91,17 -10,28 -36,94 -45,126l-2 4c-1,5 -2,9 -3,13 -9,27 -18,54 -28,81 -106,299 -647,1321 -1078,1078 -272,-153 -114,-989 -47,-1237 37,-140 -29,-75 363,-946 12,-27 131,-218 129,-211 -7,25 -89,198 -171,395 -38,90 -84,192 -117,278 -57,150 -84,280 -91,303 -12,41 -62,206 -34,278 35,-59 300,-607 359,-713 231,-413 484,-798 725,-1203 39,-65 56,-104 77,-178 -164,22 -308,61 -476,73 -259,19 -463,-7 -619,24 -497,102 -595,359 -1149,912 -63,63 -223,279 -229,285 -59,59 322,-524 352,-570 84,-131 384,-587 380,-725 -37,-11 -349,64 -435,72 -764,68 -851,79 -1431,631 -144,137 -156,161 -48,-31 81,-146 306,-474 332,-629l4 -21c-258,34 -419,89 -692,91 -152,1 -375,13 -485,55 -54,20 -19,6 -56,82 -124,256 -299,686 -399,957 -137,374 -214,675 -278,1068 -50,314 -391,785 -656,952 -215,136 -327,49 -408,-168 -140,-490 218,-1520 459,-1952 27,-49 -296,780 -292,941 2,87 65,-109 94,-177 52,-120 318,-770 416,-795 51,-13 838,-288 629,-258 -16,3 -394,-22 -452,-28 -23,-2 -47,-3 -50,-8 -19,-30 402,-799 396,-799 -62,-6 -85,24 -185,34 -129,13 -328,28 -702,68 -182,19 -290,36 -361,106 -80,77 -108,222 -181,394 -17,43 -35,81 -54,72 -119,-54 -97,-32 -190,-77 -113,-56 -140,-63 -245,-97 -73,177 -146,345 -229,519 227,30 296,2 501,36 80,13 44,50 17,130 -70,213 -149,397 -205,618 -137,582 -222,1502 403,1840 413,204 804,-45 1048,-382 320,-449 176,-349 233,63 30,218 101,355 254,512 38,-463 480,-1567 660,-2067 138,-382 370,-839 570,-1192 140,-247 -256,525 -360,766 -108,247 -243,664 -241,782 27,-2 71,-70 107,-112 85,-98 173,-226 235,-291 251,-259 486,-567 688,-706l31 -22z"
                fill={colors.grey[100]}
            />
        </g>
    </svg>);
};

export default Logo;
