import { useState } from "react";

const MoySkladIcon = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="20.56px" height="20.56px" viewBox="0 0 20 20" version="1.1">
        <g id="surface1">
            <path style={{stroke: "none", fillRule:"evenodd", fill: "rgb(39.215686%,80.392157%,100%)", fillOpacity:1}} d="M 2.90625 17.425781 C 5.402344 17.425781 7.292969 14.171875 8.988281 10.617188 C 9.800781 8.894531 10.5625 7.089844 11.324219 5.5625 C 12.15625 3.863281 13.015625 2.667969 14.0625 2.667969 C 14.949219 2.667969 15.785156 3.382812 16.617188 4.507812 C 18.34375 6.839844 19.832031 10.792969 19.832031 13.382812 C 19.832031 15.554688 18.714844 17.425781 16.425781 17.425781 Z M 2.90625 17.425781 "/>
            <path style={{stroke: "none", fillRule:"evenodd", fill: "fill:rgb(15.686275%,33.333333%,68.627451%)", fillOpacity:1}} d="M 8.988281 10.617188 C 7.269531 7.15625 6.496094 4.617188 4.976562 4.617188 C 4.03125 4.617188 2.96875 5.605469 2.007812 7.230469 C 0.976562 8.949219 0.167969 11.378906 0.167969 13.988281 C 0.167969 16.050781 1.175781 17.421875 2.90625 17.421875 C 5.40625 17.421875 7.292969 14.167969 8.988281 10.617188 "/>
        </g>
    </svg>);
};

export default MoySkladIcon;
